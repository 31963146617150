import React from "react";
import classes from "./classes.module.scss";
import {ReactComponent as GlowingArrow} from"assets/images/icons/glowing-arrow-right.svg";

type IProps = {
	loading: boolean,
	title: string,
	value: string
};

export default class MintInfo extends React.Component<IProps> {

	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<span className={classes["glowing-arrow"]}><GlowingArrow/></span>
				<div className={classes["content-container"]}>
					<p className={classes["main-info-text"]}>{this.props.title}</p>
					{!this.props.loading && <p className={classes["value-text"]}>{this.props.value}</p>}
					{this.props.loading && <div className={classes["value-loading"]}/>}
				</div>
			</div>
		);
	}

}


