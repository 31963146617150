import React from "react";
import Dropdown from "../Dropdown";

import classes from "./classes.module.scss";

export type IDropdownContent = {
	title: string | JSX.Element;
	content: string | JSX.Element;
}

type IProps = {
	dropdownContent: IDropdownContent[]
};


export default class DropdownContainer extends React.Component<IProps> {
	private closabled: (() => void)[] = [];

	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				{this.props.dropdownContent.map((dropdown, i) =>
					<Dropdown
						key={i}
						closeAction={(closabled) => this.closabled.push(closabled)}
						onOpen={(isOpen) => this.onOpen(isOpen)}
						title={dropdown.title}
						content={dropdown.content} />
				)}
			</div>
		);
	}

	private onOpen(isOpen: boolean) {
		this.closeAll();
	}

	private closeAll() {
		this.closabled.forEach((close) => {
			close();
		})
	}
}
