import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import backgroundImage from "assets/images/pages/home/background/plug-experience2.png";
import { DISCORD_LINK } from "assets/links/links";
import backgroundVideo from "assets/videos/Animation_Blister_Survol_V05.mp4";
import React from "react";
import { Link } from "react-router-dom";
import Contract from "Stores/Contract";
import Video from "../../../../Elements/Video";
import classes from "./classes.module.scss";

type IState = {
	publicActive: boolean | null;
	privateActive: boolean | null;
};

type IProps = {};
export default class PlugExperienceSection extends React.Component<IProps, IState> {
	private removeContractOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			publicActive: false,
			privateActive: false,
		};
	}

	public render(): JSX.Element {
		return (
			<section className={classes["root"]}>
				<img alt="background" src={backgroundImage} />
				<Video videoPath={backgroundVideo} videoType="video/mp4" className={classes["video"]} />
				{this.state.privateActive || this.state.publicActive ? (
					<div className={classes["box"]}>
						<div className={classes["title"]}>SALE IS LIVE</div>
						<div className={classes["content"]}>
							The world’s first NFT Pass collection transforming digital assets into high-end experiences
							& services{" "}
						</div>
						<Link className={classes["link"]} to="/mint">
							<button className={classes["button"]}>
								<div>MINT NOW</div>
								<span>
									<ArrowRight />
								</span>
							</button>
						</Link>
					</div>
				) : (
					<div className={classes["box"]}>
						<div className={classes["title"]}>
							THE MOST
							<br />
							COVETED CLUB
							<br />
							IN TOWN
						</div>
						<div className={classes["content"]}>
							Experience the ultimate exclusivity and own a piece of the action! 
							<p>Discover a new level of high-end access with the world’s first NFT Pass collection. </p>
							<p>Ready to see what you will unlock?</p>
							
						</div>
						<button className={classes["button"]} onClick={() => window.open(DISCORD_LINK, "_blank")}>
							<div>Join our community</div>
							<span>
								<ArrowRight />
							</span>
						</button>
					</div>
				)}
			</section>
		);
	}

	public async componentDidMount() {
		this.updateState();
		this.removeContractOnChange = Contract.getInstance().onChange(async () => this.onContractChange());
	}

	public componentWillUnmount() {
		this.removeContractOnChange();
	}

	private onContractChange() {
		this.updateState();
	}

	private async updateState() {
		try {
			const publicActive = await Contract.getInstance().getPublicStatus();
			const privateActive = await Contract.getInstance().getPrivateStatus();
			this.setState({
				publicActive,
				privateActive,
			});
		} catch (err) {
			console.warn(err);
		}
	}
}

