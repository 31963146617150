import React from "react";

import classes from "./classes.module.scss";
import OurTeamBox, { IProps as OurTeamBoxProps } from "Components/Materials/OurTeamBox";

import cardelGroupLogoSrc from "assets/images/logos/cardelGroup.svg";
import smartChainLogoSrc from "assets/images/logos/smart-chain.svg";

import excellJets from "assets/images/logos/excell-jets.png";
import exoticRacing from "assets/images/logos/exotic-racing.png";
import liveNation from "assets/images/logos/live-nation.png";

import smurfSociety from "assets/images/logos/smurfSociety.png";
import anthonyCarmelo from "assets/images/logos/anthonyCarmelo.png";
import trinix from "assets/images/logos/trinix.png";




export default class OurTeamSection extends React.Component {
	public render(): JSX.Element {
		const boxesData: OurTeamBoxProps[] = [
			{
				logoSrc: cardelGroupLogoSrc,
				title: "Cardel Group",
				text1: "An internationally recognized industry leader in high-end services and event management since 2001. The Cardel Group is innovating to redefine client experience by leveraging Web3 technology and ecosystem.",
				linkedInLink: "https://www.linkedin.com/company/cardel-group/",
				link: "https://www.cardel-group.com/",
			},
			{
				logoSrc: smartChainLogoSrc,
				title: "Smart-Chain",
				text1: "Launched in 2019, SmartChain is a multi-protocol innovation lab and consultancy with 50+ collaborators specialized in blockchain technology. As the technical partner, SmartChain oversees platform development and lends its expertise to develop the NFT ecosystem",
				linkedInLink: "https://www.linkedin.com/company/smartchainfrance/",
				link: "https://smart-chain.fr/",
			},
		];

		return (
			<section className={classes["root"]}>
				{this.ourTeam(boxesData)}
				{this.ourPartnersAndAdvisors()}
			</section>
		);
	}

	private ourTeam(boxesData: OurTeamBoxProps[]): JSX.Element {
		return (
			<div>
				<span className={[classes["header"], classes["title"]].join(" ")}>
					<div>our</div>
					<div className={classes["neon"]}>team</div>
				</span>
				<div className={classes["content"]}>
					{boxesData.map((data, index) => {
						const { logoSrc, title, text1, text2, link, linkedInLink } = data;
						return (
							<OurTeamBox
								key={index}
								index={index}
								logoSrc={logoSrc}
								title={title}
								text1={text1}
								text2={text2}
								linkedInLink={linkedInLink}
								link={link}
							/>
						);
					})}
				</div>
			</div>
		);
	}

	private ourPartnersAndAdvisors(): JSX.Element {
		return (
			<div className={classes["partner-section"]}>
				<div className={[classes["header"], classes["title"]].join(" ")}>
					<div>our</div>
					<div className={classes["neon"]}>partners, advisors and ambassador</div>
				</div>
				<div className={classes["partners-container-row1"]}>
					<a href="https://www.excelljets.net/" target="_blank" rel="noreferrer">
						<img src={excellJets} alt="Excell Jets" />
					</a>
					<a href="https://exoticsracing.com/" target="_blank" rel="noreferrer">
						<img src={exoticRacing} alt="Exotic Racing" />
					</a>
					<a href="https://www.livenation.fr/" target="_blank" rel="noreferrer">
						<img src={liveNation} alt="Live Nation" />
					</a>
				</div>
				<div className={classes["partners-container-row2"]}>
					<a href="https://thesmurfssociety.com/" target="_blank" rel="noreferrer">
						<img src={smurfSociety} alt="Smurf Society" />
					</a>
					<a href="https://twitter.com/carmeloanthony" target="_blank" rel="noreferrer">
						<img src={anthonyCarmelo} alt="Anthony Carmelo" />
					</a>
					<a href="https://www.instagram.com/trinixmusic/" target="_blank" rel="noreferrer">
						<img src={trinix} alt="Trinix" />
					</a>
				</div>
			</div>
		);
	}
}
