import React from "react";
import classes from "./classes.module.scss";

import { ReactComponent as Ellipse } from "assets/images/icons/ellipse.svg"

type IProps = {}
export default class Text extends React.Component<IProps> {
	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<span><Ellipse /></span>
				<p className={classes["title"]}>
					{this.props.children}
				</p>
			</div>
		);
	}
}
