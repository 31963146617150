import profileIcon from "assets/images/icons/profile.svg";
import { ReactComponent as Discord } from "assets/images/logos/discord.svg";
import { ReactComponent as Instagram } from "assets/images/logos/instagram.svg";
import { ReactComponent as Twitter } from "assets/images/logos/twitter.svg";
import { DISCORD_LINK, INSTAGRAM_LINK, TWITTER_LINK } from "assets/links/links";
import Config from "Configs";
import React from "react";
import { Link } from "react-router-dom";
import TopMenuStatus, { EOpeningState } from "../../../Stores/TopMenuStatus";
import ConnectWallet from "../ConnectWallet";
import WalletAddressShort from "../WalletAddressShort.tsx";
import classes from "./classes.module.scss";

type IState = {
	status: EOpeningState;
};
type IProps = {};

export default class TopMenu extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};

	public constructor(props: IProps) {
		super(props);
		this.updateStatus();
		this.state = {
			status: TopMenuStatus.getInstance().status,
		};
	}

	public render(): JSX.Element {
		const version: string = Config.getInstance().get().version;
		return (
			<div className={classes["root"]} {...this.state}>
				<div className={classes["shadow"]} onClick={() => TopMenuStatus.getInstance().close()} />
				<div className={classes["nav"]} {...this.state}>
					<nav className={classes["top-menu-nav"]}>
						{version === "v1" && (
							<>
								<WalletAddressShort icon={profileIcon} />
								<ConnectWallet />
							</>
						)}
						<Link
							onClick={() => this.scrollTopCloseMenu()}
							className={[classes["item"], classes["nav-text"]].join(" ")}
							to="/"
						>
							Home
						</Link>
						{version === "v1" && (
							<Link className={classes["nav-text"]} to="/mint">
								Mint
							</Link>
						)}

						<a
							className={[classes["item"], classes["social-link"]].join(" ")}
							href={TWITTER_LINK}
							target="_blank"
							rel="noreferrer"
						>
							<Twitter />
						</a>
						<a
							className={[classes["item"], classes["social-link"], classes["insta"]].join(" ")}
							href={INSTAGRAM_LINK}
							target="_blank"
							rel="noreferrer"
						>
							<Instagram />
						</a>
						<a
							className={[classes["item"], classes["social-link"]].join(" ")}
							href={DISCORD_LINK}
							target="_blank"
							rel="noreferrer"
						>
							<Discord />
						</a>
					</nav>
				</div>
			</div>
		);
	}

	private updateStatus() {
		document.body.setAttribute("top-menu-status", TopMenuStatus.getInstance().status);
		this.setState({
			status: TopMenuStatus.getInstance().status,
		});
	}

	public componentDidMount() {
		this.removeOnSwitch = TopMenuStatus.getInstance().onSwitch((type) => {
			this.updateStatus();
		});
	}

	public componentWillUnmount() {
		this.removeOnSwitch();
	}

	private scrollTopCloseMenu() {
		window.scrollTo(0, 0);
		TopMenuStatus.getInstance().close();
	}
}

