import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import Mint from "../Mint";
import FaqSection from "./sections/FaqSection";
import OurTeamSection from "./sections/OurTeamSection";
import PlugExperienceSection from "./sections/PlugExperienceSection";

// import PlugExperienceSection from "./sections/PlugExperienceSection";
import RealRoadmap from "./sections/RealRoadmap";
import RealWorldSection from "./sections/RealWorldSection";
import ThePassSection from "./sections/ThePassSection";

export default class Home extends BasePage {
	public render(): JSX.Element {
		return (
			<DefaultTemplate title="The Plug">
				<Mint />
				<PlugExperienceSection />
				<ThePassSection />
				<RealWorldSection />
				{/* <EmpoweringBlockchainSection /> */}
				<RealRoadmap />
				{/* <ExperiencesRoadmap /> */}
				<OurTeamSection />
				<FaqSection />
			</DefaultTemplate>
		);
	}
}
