import React from "react";

type IProps = {
	videoPath: string;
	videoType: string;
	className?: string;
};

export default class Video extends React.Component<IProps> {
	public render(): JSX.Element {
		return (
			<video autoPlay muted loop className={this.props.className ?? ""}>
				<source src={this.props.videoPath} type={this.props.videoType} />
			</video>
		);
	}
}
