import Config from "Configs";
import { MintEntity } from "Entities";
import BaseService from "../BaseService";

type IParamsGet = { userAddress: string };

type IQueryString = {};

export type IParamsPost = {
	address: string | null;
};

export default class Whitelist extends BaseService {
	protected static readonly baseUrl = Config.getInstance().get().apis.whitelist;

	public static async isWhitelisted(params: IParamsGet, queryString: IQueryString = {}): Promise<boolean> {
		const webServiceUrl = new URL(
			`${Whitelist.baseUrl}/${Config.getInstance().get().contracts.nftCollection.slug}/isWhitelisted/${params.userAddress}`,
		);
		return await this.httpGet(webServiceUrl);
	}

	public static async signMessage(params: IParamsPost): Promise<MintEntity> {
		const webServiceUrl = new URL(
			`${Whitelist.baseUrl}/${Config.getInstance().get().contracts.nftCollection.slug}/signMessage`,
		);
		return await this.httpPost(webServiceUrl, params);
	}
}

