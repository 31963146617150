import { EProtocol } from "Entities/Mint/EthContractConfigEntity";
import { ISignature } from "Entities/Mint/SignatureEntity";
import { ethers } from "ethers";
import LightCache from "Services/LightCache";
import EthBigNumber from "Services/Wallet/EthBigNumber";
import BaseContract from "./BaseContract";

export default class NftCollectionContract extends BaseContract {
	protected protocol: EProtocol = EProtocol.ERC721;
	protected static ctx: NftCollectionContract;
	protected readonly nftContractCache: LightCache;
	protected static readonly QUERY_DELAY = 8;
	constructor(
		protected contractAddress: string,
		protected abi: any,
		protected provider: ethers.providers.InfuraProvider | ethers.providers.Web3Provider,
		protected signer: ethers.providers.JsonRpcSigner | null,
	) {
		super(contractAddress, abi, provider, signer);
		this.nftContractCache = LightCache.getNewNameSpace();
	}

	public async getBalance(userAddress: number): Promise<EthBigNumber> {
		try {
			const cacheTokensSold = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-tokens-sold`);
			if (cacheTokensSold) return cacheTokensSold;
			const tokensSold = new EthBigNumber(await this.contract!["balanceOfUser"](userAddress));
			this.nftContractCache.set(`${this.contractAddress}-tokens-sold`, tokensSold, NftCollectionContract.QUERY_DELAY);
			return tokensSold;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getMaxTotalSupply() : Promise<EthBigNumber> {
		try {
			const cacheMaxSupply = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-max-total-supply`);
			if (cacheMaxSupply) return cacheMaxSupply;
			const maxSupply = new EthBigNumber(await this.contract!["maxTotalSupply"]());
			this.nftContractCache.set(`${this.contractAddress}-max-total-supply`, maxSupply, NftCollectionContract.QUERY_DELAY);
			return maxSupply;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getTotalSupply() : Promise<EthBigNumber> {
		try {
			const cacheTotalSupply = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-total-supply`);
			if (cacheTotalSupply) return cacheTotalSupply;
			const totalSupply = new EthBigNumber(await this.contract!["totalSupply"]());
			this.nftContractCache.set(`${this.contractAddress}-total-supply`, totalSupply, NftCollectionContract.QUERY_DELAY);
			return totalSupply;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getPublicStatus(): Promise<boolean> {
		try {
			const cachePublicStatus = this.nftContractCache.get<boolean>(`${this.contractAddress}-public-status`);
			if (cachePublicStatus) return cachePublicStatus;
			const publicStatus = await this.contract!["publicSaleStatus"]();
			this.nftContractCache.set(`${this.contractAddress}-public-status`, publicStatus, NftCollectionContract.QUERY_DELAY);
			return publicStatus;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getPrivateStatus(): Promise<boolean> {
		try {
			const cachePrivateStatus = this.nftContractCache.get<boolean>(`${this.contractAddress}-private-status`);
			if (cachePrivateStatus) return cachePrivateStatus;
			const privateStatus = await this.contract!["privateSaleStatus"]();
			this.nftContractCache.set(`${this.contractAddress}-private-status`, privateStatus, NftCollectionContract.QUERY_DELAY);
			return privateStatus;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getPrivateHardcap(): Promise<EthBigNumber> {
		try {
			const cachePrivateHardcap = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-public-max-mint-per-tx`);
			if (cachePrivateHardcap) return cachePrivateHardcap;
			const privateHarcap = new EthBigNumber(await this.contract!["privateSaleHardcap"]());
			this.nftContractCache.set(`${this.contractAddress}-private-hardcap`, privateHarcap, NftCollectionContract.QUERY_DELAY);
			return privateHarcap;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getPublicMaxMintPerTx(): Promise<EthBigNumber> {
		try {
			const cachePublicMaxMintPerTx = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-public-max-mint-per-tx`);
			if (cachePublicMaxMintPerTx) return cachePublicMaxMintPerTx;
			const publicMaxMintPerTx = new EthBigNumber(await this.contract!["maxPublicSaleMintPerTx"]());
			this.nftContractCache.set(`${this.contractAddress}-public-max-mint-per-tx`, publicMaxMintPerTx, NftCollectionContract.QUERY_DELAY);
			return publicMaxMintPerTx;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getPrivateMaxMintPerTx(): Promise<EthBigNumber> {
		try {
			const cachePrivateMaxMintPerTx = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-private-max-mint-per-tx`);
			if (cachePrivateMaxMintPerTx) return cachePrivateMaxMintPerTx;
			const privateMaxMintPerTx = new EthBigNumber(await this.contract!["maxPrivateSaleMintPerTx"]());
			this.nftContractCache.set(`${this.contractAddress}-private-max-mint-per-tx`, privateMaxMintPerTx, NftCollectionContract.QUERY_DELAY);
			return privateMaxMintPerTx;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getAirdropTokens(): Promise<EthBigNumber> {
		try {
			const cacheAirdropTokens = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-airdrop-tokens`);
			if (cacheAirdropTokens) return cacheAirdropTokens;
			const airdropTokens = new EthBigNumber(await this.contract!["totalReservedForAirdrop"]());
			this.nftContractCache.set(`${this.contractAddress}-airdrop-tokens`, airdropTokens, NftCollectionContract.QUERY_DELAY);
			return airdropTokens;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getPrivateTokensSold(): Promise<EthBigNumber> {
		try {
			const cachePrivateTokensSold = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-private-tokens-sold`);
			if (cachePrivateTokensSold) return cachePrivateTokensSold;
			const privateTokensSold = new EthBigNumber(await this.contract!["totalSoldInPrivateSale"]());
			this.nftContractCache.set(`${this.contractAddress}-private-tokens-sold`, privateTokensSold, NftCollectionContract.QUERY_DELAY);
			return privateTokensSold;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}


	public async getPublicTokensSold(): Promise<EthBigNumber> {
		try {
			const cachePublicTokensSold = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-public-tokens-sold`);
			if (cachePublicTokensSold) return cachePublicTokensSold;
			const publicTokensSold = new EthBigNumber(await this.contract!["totalSoldInPublicSale"]());
			this.nftContractCache.set(`${this.contractAddress}-public-tokens-sold`, publicTokensSold, NftCollectionContract.QUERY_DELAY);
			return publicTokensSold;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getPrivatePrice(): Promise<EthBigNumber> {
		try {
			const cachePrivatePrice = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-private-price`);
			if (cachePrivatePrice) return cachePrivatePrice;
			const privatePrice = new EthBigNumber(await this.contract!["privateSalePrice"]());
			this.nftContractCache.set(`${this.contractAddress}-private-price`, privatePrice, NftCollectionContract.QUERY_DELAY);
			return privatePrice;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}


	public async getPublicPrice(): Promise<EthBigNumber> {
		try {
			const cachePublicPrice = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-public-price`);
			if (cachePublicPrice) return cachePublicPrice;
			const publicPrice = new EthBigNumber(await this.contract!["publicSalePrice"]());
			this.nftContractCache.set(`${this.contractAddress}-public-price`, publicPrice, NftCollectionContract.QUERY_DELAY);
			return publicPrice;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}

	public async getUserPrivateSaleMintedAmount(userAddress: string): Promise<EthBigNumber> {
		try {
			const cacheUserMintedAmount = this.nftContractCache.get<EthBigNumber>(`${this.contractAddress}-user-${userAddress}-private-minted-amount`);
			if (cacheUserMintedAmount) return cacheUserMintedAmount;
			const userMintedAmount = new EthBigNumber(await this.contract!["addressToPrivateSaleMintingAmount"](userAddress));
			this.nftContractCache.set(`${this.contractAddress}-user-${userAddress}-private-minted-amount`, userMintedAmount, NftCollectionContract.QUERY_DELAY);
			return userMintedAmount;
		} catch (e) {
			if (e instanceof Error) throw e;
			throw new Error(e as string);
		}
	}
	public async mintPublicSale(amount: EthBigNumber, price: EthBigNumber) {
		try {
			return await this.contract!["mintPublicSale"](amount.getBigNumber(), { value: price.mul(amount).getBigNumber() });
		} catch (e) {
			console.error(e);
		}
	}


	

	public async mintPrivateSale(signature: ISignature, amount: EthBigNumber, price: EthBigNumber) {
		try {
			const { v, r, s } = signature;
			return await this.contract!["mintPrivateSale"](amount.getBigNumber(), { v, r, s }, { value: price.mul(amount).getBigNumber() });
		} catch (e) {
			console.error(e);
		}
	}
}

