import DropdownContainer, { IDropdownContent } from "Components/Materials/DropdownContainer";
import React from "react";
import classes from "./classes.module.scss";
import Text from "./Text";
import Title from "./Title";



export default class FaqSection extends React.Component {
	public render(): JSX.Element {
		const faqData: IDropdownContent[] = [
			{
				title: <Title>What is a blockchain?</Title>,
				content: <Text>Blockchain is a digital ledger technology that allows for secure and decentralized transactions between multiple parties without the need for a central authority.
				</Text>
			},
			{
				title: <Title>What is an NFT?</Title>,
				content: <Text>NFT stands for non-fungible token, which is a type of digital asset that represents ownership of a unique item or piece of content, such as an image, video, or music file. NFTs are typically stored on a blockchain.This makes it possible to verify the authenticity and ownership of an NFT, as well as track its transaction history.
				</Text>
			},
			{
				title: <Title>Which blockchain will be used? </Title>,
				content: <Text>The Plug will be launched on Ethereum blockchain. Ethereum is an open-source, decentralized blockchain platform that enables the creation of smart contracts and decentralized applications (DApps).
				</Text>
			},
			{
				title: <Title>What is a mint?</Title>,
				content: <Text>In Web3, a mint refers to the process of creating new tokens in a cryptocurrency ecosystem. Minting is the process of generating a new token and adding it to the circulating supply of a cryptocurrency.
				</Text>
			},
			{
				title: <Title>Why is Cardel Group creating The Plug?</Title>,
				content: <Text>Cardel Group is creating The Plug to bridge digital asset ownership and high-end experiences and services. The Group is focused on enabling users to access experiences in lifestyle, travel, sports and entertainment via Web3.
				</Text>
			},
			{
				title: <Title>I am interested in joining The Plug Experience club, how can I do that?</Title>,
				content: <Text>In order to join The Plug Experience Club, you must own a Plug Experience NFT (wait for a sale or buy it on the secondary market). The supply will be limited!
				</Text>
			},
			{
				title: <Title>How can I own a Plug Experience NFT?</Title>,
				content: <Text>These passes will be made available for minting soon on this site. Minted passes can be purchased on the secondary marketplace, <a href="https://opensea.io/collection/the-plug-experience" target="_blank" style={{textDecoration: "underline"}}rel="noreferrer" >OpenSea</a>.
				</Text>
			},
			{
				title: <Title>Why should I buy Plug Experience NFT?</Title>,
				content: <Text>To be part of the Plug Experience Club and raffles that guarantee you access to the most exclusive events in town.
				</Text>
			},
			{
				title: <Title>How can I collaborate with The Plug Experience ?</Title>,
				content: <Text>Join the Discord community and reach out to the community manager to know more. 

				</Text>
			}
		]

		return (
			<section className={classes["root"]}>
				<div className={[classes["header"], classes["title"]].join(" ")}>
					<div>The Plug</div>
					<div className={classes["neon"]}>FAQ</div>
				</div>
				<DropdownContainer dropdownContent={faqData} />
			</section>
		);
	}
}
