import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	price: string,
	crypto: string
};

export default class MintPrice extends React.Component<IProps> {

	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<p className={classes["legend-text"]}>Price</p>
				<p className={classes["price-text"]}>{this.props.price} {this.props.crypto}</p>
			</div>
		);
	}

}


