import React from "react";
import classes from "./classes.module.scss";

import { ReactComponent as LinkedInLogo } from "assets/images/logos/linkedIn.svg";
import { ReactComponent as ShareLogo } from "assets/images/logos/share-logo.svg";

export type IProps = {
	index?: number,
	logoSrc: string;
	title: string;
	text1: string;
	text2?: string;
	linkedInLink: string;
	link: string;
};

export default class OurTeamBox extends React.Component<IProps> {
	public render(): JSX.Element {
		const {index, logoSrc, title, text1, text2, link, linkedInLink} = this.props
		return (
			<div key={index} className={classes["root"]}>
				<img alt="logo" src={logoSrc} />
				<div className={classes["content"]}>
					<div className={classes["title"]}>{title}</div>
					<div className={classes["text"]}>{text1}</div>
					<div className={classes["text"]}>{text2}</div>
				</div>
				<div className={classes["social-container"]}>
					<a
						className={classes["social-link"]}
						href={linkedInLink}
						target="_blank"
						rel="noreferrer"
					>
						<LinkedInLogo />
					</a>
					<a
						className={classes["social-link"]}
						href={link}
						target="_blank"
						rel="noreferrer"
					>
						<ShareLogo />
					</a>
				</div>
			</div>
		);
	}
}

