import React from "react";
import classes from "./classes.module.scss";

import { ReactComponent as Minus } from "assets/images/icons/minus.svg";
import { ReactComponent as Plus } from "assets/images/icons/plus.svg";

type IProps = {
	onChange?: (value: number) => void
	max: number;
};

type IState = {
	number: number;
}

export default class PlusMinusButton extends React.Component<IProps, IState> {

	private readonly MIN = 1;

	constructor(props: IProps) {
		super(props)
		this.state = {
			number: 1
		}
	}

	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<Minus className={classes["button"]} onClick={() => this.increment(-1)} />
				<input
					readOnly={true}
					className={classes["input"]}
					value={this.state.number} />
				<Plus className={classes["button"]} onClick={() => this.increment(1)} />
			</div>
		);
	}

	private increment(num: number) {
		let number = this.state.number + num;
		if (number > this.props.max) number = this.props.max;
		if (number < this.MIN) number = this.MIN;

		this.setState({ number });
		if (this.props.onChange) this.props.onChange(number);
	}
}
