import Button from "Components/Elements/Button";
import React from "react";
import Wallet, { IWallet } from "Stores/Wallet";

type IProps = {
	onClick?: () => void;
	disabled?: boolean;
};
type IState = {
	userAddress: string | null;
};

export default class MintConnectWallet extends React.Component<IProps, IState> {
	private removeOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			userAddress: Wallet.getInstance().walletData?.userAddress ?? null,
		};
	}

	public render(): JSX.Element {
		const isConnected = !!this.state.userAddress;
		return (
			<>
				{!isConnected && (
					<Button variant="ghost" onClick={() => this.connect()}>
						Connect wallet
					</Button>
				)}
				{isConnected && (
					<Button variant="primary" sizing="xxl" onClick={this.props.onClick} disabled={this.props.disabled}>
						Mint
					</Button>
				)}
			</>
		);
	}

	public componentDidMount() {
		this.removeOnChange = Wallet.getInstance().onChange((web3Event: IWallet | null) => this.onChange(web3Event));
	}

	public componentWillUnmount() {
		this.removeOnChange();
	}

	private async connect(): Promise<void> {
		Wallet.getInstance().connect();
	}

	private onChange(walletData: IWallet | null) {
		this.setState({
			userAddress: walletData?.userAddress ?? null,
		});
	}
}
