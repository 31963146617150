import React from "react";

import RealWorldBox from "Components/Materials/RealWorldBox";
import { TwitterTweetEmbed } from "react-twitter-embed";

import classes from "./classes.module.scss";
import privateClubImage from "assets/images/pages/home/background/private-club.png";
import raffleVideo from "assets/videos/mood-theplug-site.mp4";

type RealWorldData = {
	title: string;
	description: string;
	text1: string;
	text2?: string;
	text3?: string;
	text4?: string;
	checklist: (string | JSX.Element)[];
	textPosition: "right" | "left";
	mediaSrc: string;
	mediaType: "video" | "image";
};

export default class RealWorldSection extends React.Component {
	public render(): JSX.Element {
		const plugPrivateClub: RealWorldData = {
			title: "the plug private club",
			description:
				"With the Plug NFT, you can rub shoulders with the who's who of town and indulge in a variety of activities.",
			text1: "Depending on the rarity of your NFT passes and budget, Cardel Group will help you elevate your lifestyle to the next level by offering you saving of up to 30% on:",
			checklist: [
				"Sports and concerts VIP hospitality packages",
				"Dining experiences",
				"Sportswear Outfits and luxury goods",
				"Flight tickets (economy to first class)",
				"Tailor-made travel itineraries",
				"Shared private flights",
				"Hotel stays (from 3 to 5 stars)",
			],
			text4: "The list goes on and you’ll always be in for a treat at this club. ",
			textPosition: "right",
			mediaSrc: privateClubImage,
			mediaType: "image",
		};

		const plugRaffles: RealWorldData = {
			title: "the plug raffles",
			description: "Are you the next lucky winner?",
			text1: "Your Plug NFT unlocks real world experiences and is full of surprises. By holding the NFT, you have exclusive access to free weekly raffles.",
			text2: "It could be you that wins a travel package, front row tickets at a sporting event or the owner of luxury goodies.",
			text3: "We have already given away over $20,000 worth of prizes since the private sale!",
			checklist: [
				"Travel packages",
				"Breathtaking sports events",
				"Sports and Luxury goods drops",
				"Entertainment experiences",
			],
			textPosition: "left",
			mediaSrc: raffleVideo,
			mediaType: "video",
		};

		const tweetsIds: string[] = [
			"1631338581745881089", "1617568199733657614", "1638223947148980225", "1634237681512136711"
		]

		// function privateGroundText() {
		// 	return <div className={classes["last-check-list-item"]}>
		// 		<div>
		// 			Private Ground transportation
		// 		</div>
		// 		<div className={classes["and-more"]}>
		// 			And so much more...
		// 		</div>
		// 	</div>
		// }

		return (
			<section className={classes["root"]}>
				<div className={[classes["header"], classes["title"]].join(" ")}>
					<div>Real-world</div>
					<div className={[classes["neon"], classes["yellow"]].join(" ")}>high-end utilities</div>
				</div>
				<div className={classes["content"]}>
					<RealWorldBox
						title={plugPrivateClub.title}
						description={plugPrivateClub.description}
						text1={plugPrivateClub.text1}
						text4={plugPrivateClub.text4}
						checklist={plugPrivateClub.checklist}
						textPosition={plugPrivateClub.textPosition}
						mediaSrc={plugPrivateClub.mediaSrc}
						mediaType={plugPrivateClub.mediaType}
					/>
					<RealWorldBox
						title={plugRaffles.title}
						description={plugRaffles.description}
						text1={plugRaffles.text1}
						text2={plugRaffles.text2}
						text3={plugRaffles.text3}
						checklist={plugRaffles.checklist}
						textPosition={plugRaffles.textPosition}
						mediaSrc={plugRaffles.mediaSrc}
						mediaType={plugRaffles.mediaType}
					/>
				</div>
				<div className={[classes["footer"], classes["title"]].join(" ")}>
					<div className={classes["footer-subtitle"]}>AUGMENT YOUR OWNERSHIP OF VALUABLES</div>
					<div className={classes["footer-title-container"]}>
						<div>mint</div>
						<div className={classes["yellow"]}>earn</div>
						<div className={classes["orange"]}>experiment</div>
					</div>

					<div className={classes["tweetsContainer"]}>
						{tweetsIds.map((tweetId)=> {
							return (<div className={classes["tweet"]}>
							<TwitterTweetEmbed tweetId={tweetId} />
						</div>)
						})}
					</div>

					<div className={classes["proof-paragraph"]}>{plugRaffles.text3}</div>
					<div className={classes["youtube-iframe"]}>
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/Cns-I5tE_2o"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
							className={classes["youtube-iframe-content"]}
						></iframe>
					</div>
				</div>
			</section>
		);
	}
}
