import profileIcon from "assets/images/icons/profile.svg";
import { ReactComponent as Discord } from "assets/images/logos/discord.svg";
import { ReactComponent as Instagram } from "assets/images/logos/instagram.svg";
import logomobile, { ReactComponent as LogoMobile } from "assets/images/logos/logo-mobile.svg";
import logo from "assets/images/logos/logo_the_plug.png";
import { ReactComponent as Twitter } from "assets/images/logos/twitter.svg";
import { DISCORD_LINK, INSTAGRAM_LINK, TWITTER_LINK } from 'assets/links/links';
import Config from "Configs";
import React from "react";
import { Link } from "react-router-dom";
import WindowStore from "Stores/WindowStore";
import ConnectWallet from '../ConnectWallet';
import MenuButton from "../MenuButton";
import WalletAddressShort from "../WalletAddressShort.tsx";
import classes from "./classes.module.scss";


type IState = {
	open: 1 | 0 | -1;
};
type IProps = {};
export default class Header extends React.Component<IProps, IState> {
	private onScrollYDirectionChange = () => { };

	constructor(props: IProps) {
		super(props);

		this.state = {
			open: 0,
		};
	}

	public render(): JSX.Element {
		const version: string = Config.getInstance().get().version;
		return (
			<header className={classes["root"]} data-open={this.state.open}>
				<div className={classes["desktop"]}>
					<Link className={classes["logo-desktop"]} to="/">
						<img alt="logo" src={logo} />
					</Link>
					<div className={classes["right"]}>
						<div className={classes["nav-container"]}>
							<Link className={classes["logo-small"]} to="/">
								<img alt="logo" src={logomobile} />
							</Link>
							{/* <Link className={classes["nav-text"]} to="/">
								Home
							</Link> */}
							{version === "v1" &&
								<>
									{/* <Link className={classes["nav-text"]} to="/mint">
										Mint
									</Link> */}
									<WalletAddressShort icon={profileIcon} />
									<ConnectWallet />
								</>
							}
							<a
								className={classes["social-link"]}
								href={TWITTER_LINK}
								target="_blank"
								rel="noreferrer"
							>
								<Twitter />
							</a>
							<a
								className={[classes["social-link"], classes["insta"]].join(" ")}
								href={INSTAGRAM_LINK}
								target="_blank"
								rel="noreferrer"
							>
								<Instagram />
							</a>
							<a
								className={classes["social-link"]}
								href={DISCORD_LINK}
								target="_blank"
								rel="noreferrer"
							>
								<Discord />
							</a>
						</div>
					</div>
				</div>
				<div className={classes["mobile"]}>
					<Link to="/">
						<LogoMobile />
					</Link>
					<div className={classes["burger"]}>
						<MenuButton />
					</div>
				</div>
			</header>
		);
	}

	public componentDidMount() {
		this.onScrollYDirectionChange = WindowStore.getInstance().onScrollYDirectionChange((scrollYDirection) =>
			this.visibility(scrollYDirection),
		);
	}

	public componentWillUnmount() {
		this.onScrollYDirectionChange();
	}

	private visibility(scrollYDirection: number) {
		let open: IState["open"] = 1;
		if (Math.abs(scrollYDirection) < 40) return;
		if (window.scrollY > 200 && scrollYDirection < 0) {
			open = -1;
		}

		if (open !== this.state.open) this.setState({ open });
	}
}

