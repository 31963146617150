import classes from "./classes.module.scss";
import React from "react";
import { ReactComponent as RoadmapPoint } from "assets/images/icons/roadmapPoint.svg";
import { ReactComponent as RoadmapBlackPoint } from "assets/images/icons/roadmapBlackPoint.svg";

export type IProps = {
	borderVariant: "plain" | "cut";
	type: "previous" | "current" | "next";
	label?: { date: string; context?: string; dateVariant: "caption" | "title" };
	points: string[];
};

type IState = {};
export default class Period extends React.Component<IProps, IState> {
	public render() {
		const period = this.props;
		return (
			<div className={[classes["item"], classes["q1-2022"]].join(" ")} data-variant={period.type}>
				<div className={classes["left"]}>{this.getLabel(period)}</div>
				<div className={classes["middle"]}>
					{period.type === "previous" && <div className={classes["lineVertical"]} />}
					{period.type === "current" && <div className={classes["lineVertical"]} />}
					{period.type === "next" && <div className={classes["lineVertical"]} data-variant="empty" />}

					{period.type === "previous" && (
						<span className={classes["point"]}>
							<RoadmapPoint />
						</span>
					)}
					{period.type === "current" && (
						<span className={classes["point"]}>
							<RoadmapPoint />
						</span>
					)}

					{period.type === "next" && (
						<span className={classes["point"]}>
							<RoadmapBlackPoint />
						</span>
					)}

					{period.type === "previous" && <div className={classes["lineVertical"]} />}
					{period.type === "current" && <div className={classes["lineVertical"]} data-variant="empty" />}
					{period.type === "next" && <div className={classes["lineVertical"]} data-variant="empty" />}
				</div>
				<div className={classes["right"]}>
					<div className={classes["right-label"]}>{this.getLabel(period)}</div>
					<div className={classes["lineHorizontal"]} />
					<div className={classes["boxList"]} border-variant={period.borderVariant}>
						<div className={classes["borderBox"]}></div>
						<ul>
							{period.points.map((txt, i) => (
								<li key={i}>{txt}</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		);
	}

	private getLabel(period: IProps) {
		return (
			<div className={classes["label"]} date-variant={period.label?.dateVariant}>
				<div>{period.label?.date}</div>
				<div className={classes["context"]}>{period.label?.context}</div>
			</div>
		);
	}
}

