import React from "react";

//replace img beacon by <Video videoPath={leftVideo} videoType="video/mp4" />
// import Video from "../../../Elements/Video";
import leftVideo from "assets/videos/Blister_V28.png";
import discord from "assets/images/logos/discord3.png";

import classes from "./classes.module.scss";
import { DISCORD_LINK } from "assets/links/links";

export default class ThePassSection extends React.Component {
	public render(): JSX.Element {
		return (
			<section className={classes["root"]}>
				<img src={leftVideo} alt="blister animation" />
				<div className={classes["content-container"]}>
					<div className={classes["glowing-title"]}>THE PASS</div>
					<div className={classes["main-title"]}>OWN THE PASS TO BE PART OF THE CLUB.</div>
					<div className={classes["description"]}>
						Built on the Ethereum Blockchain - with a limited supply - this collection is a series of
						one-of-a-kind pieces that blur the line between reality and fantasy and bridges the gap between
						Web3 and the « real world ».
						<br />
						<br />
						The collection is your ticket to explore an exclusive club with each piece taking you on a
						journey of VIP events, private gatherings and secret locations.
						<br />
						<br />
						There are 3 types of Plug Passes: Silver, Gold and Diamond.
						<br />
						<br />
						The Diamond rarity trait gives you a 30% discount, gold 20% and silver 10%. The reveal of the
						NFT will be done once the different sales phases are over.
						<br />
						<br />
						Don’t miss out on the opportunity to experience the world in a way that few ever have.
						<br />
						<br />
						<div className={classes["discord"]}>
							<div className={classes["discord-content"]}>
								Join our Discord and stay tuned because rumor has it that a new sale will be released
								soon.
							</div>
							<div className={classes["discord-link"]}>
								<a className={classes["logo"]} href={DISCORD_LINK} target="_blank" rel="noreferrer">
									<img alt="Discord logo" src={discord} />
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

