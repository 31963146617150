import { ReactComponent as Instagram } from "assets/images/logos/instagram2.svg";
import logo from "assets/images/logos/logo_the_plug.png";
import { ReactComponent as Twitter } from "assets/images/logos/twitter2.svg";
import { ReactComponent as Discord } from "assets/images/logos/discord2.svg";
import { DISCORD_LINK, INSTAGRAM_LINK, TWITTER_LINK } from "assets/links/links";
import React from "react";
import { Link } from "react-router-dom";
import classes from "./classes.module.scss";

type IProps = {
	action?: string;
};

type IState = {
	userEmail: string | null;
	disabled: boolean;
};

export default class Home extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			userEmail: null,
			disabled: true,
		};

		this.handleChange = this.handleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	public override render(): JSX.Element | null {
		return (
			<footer className={classes["root"]}>
				<div className={classes["content"]}>
					<Link className={classes["logo"]} to="/">
						<img alt="logo" src={logo} />
					</Link>
					<div className={classes["right"]}>
						<div className={classes["title"]}>
							Don't miss <br />a drop ever again
						</div>
						<iframe src="./newsletter" className={classes["newsletter-frame"]} title="mailChimp" />
						<div className={classes["social-container"]}>
							<a href={TWITTER_LINK} target="_blank" rel="noreferrer">
								<Twitter />
							</a>
							<a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
								<Instagram />
							</a>
							<a href={DISCORD_LINK} target="_blank" rel="noreferrer">
								<Discord />
							</a>
						</div>
					</div>
				</div>
				<div className={classes["copyright"]}>Copyright © 2023. All rights reserved by The Plug.</div>
			</footer>
		);
	}

	private handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
		if (this.state.userEmail === "") {
			this.setState({ userEmail: e.target.value }, () => this.isSubmitButtonDisabled());
		}
		this.setState({ userEmail: e.target.value }, () => this.isSubmitButtonDisabled());
	}

	private isSubmitButtonDisabled() {
		if (this.state.userEmail === "") {
			this.setState({ disabled: true });
		} else {
			this.setState({ disabled: false });
		}
		return this.state.disabled;
	}

	private async onSubmit(e: any): Promise<void> {
		e.preventDefault();
		const email = this.state.userEmail;
		const responseADD = await fetch(
			`https://smart-chain.us8.list-manage.com/subscribe/post-json?u=${process.env["REACT_APP_MAILCHIMP_U"]}&id=${process.env["REACT_APP_MAILCHIMP_ID"]}&f_id=${process.env["REACT_APP_MAILCHIMP_f_id"]}&EMAIL=${email}`,
			{
				method: "GET",
				mode: "no-cors",
			},
		);
		console.log("responseADD", responseADD);
		const responseGET = await fetch(
			`https://smart-chain.us8.list-manage.com/subscribe/post-json?u=${process.env["REACT_APP_MAILCHIMP_U"]}&id=${process.env["REACT_APP_MAILCHIMP_ID"]}&f_id=${process.env["REACT_APP_MAILCHIMP_f_id"]}`,
			{
				method: "GET",
				mode: "no-cors",
			},
		);
		console.log("responseGET", responseGET);
	}
}
