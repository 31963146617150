
export enum EProtocol {
	ERC20 = "ERC20",
	ERC721 = "ERC721",
	MARKETPLACE = "MARKETPLACE",
	UNDEFINED = "UNDEFINED",
}

export class Abi {}

export default class EthContractConfigEntity {

	public name?: string;

	public version?: string;
	
	public protocol!: EProtocol;

	public abi!: Abi[];

	public chainId?: number;

	public address?: string;

	public transferProxy?: string;
}
