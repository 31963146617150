import React from "react";
import classes from "./classes.module.scss";

import { ReactComponent as CheckIcon } from "assets/images/icons/check.svg";

import raffleImage from "assets/images/pages/home/background/raffle.png";
import { DISCORD_LINK } from "assets/links/links";

type IProps = {
	title: string;
	description: string;
	text1: string;
	text2?: string;
	text3?: string;
	text4?: string;
	checklist: (string | JSX.Element)[];
	textPosition: "right" | "left";
	mediaSrc: string;
	mediaType: "video" | "image";
	imageRedirectToDiscord?: boolean;
};

export default class RealWorldBox extends React.Component<IProps> {
	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<div className={classes["content"]} data-text-position={this.props.textPosition}>
					<div
						className={classes["media"]}
						data-name={this.props.mediaType}
						data-variant={this.props.imageRedirectToDiscord ? "redirect" : null}
						onClick={() => {
							if (!this.props.imageRedirectToDiscord) return;
							window.open(DISCORD_LINK, "_blank");
						}}
					>
						{this.props.mediaType === "video" && (
							<>
								<video
									autoPlay
									muted
									loop
									className={classes["video"]}
									src={this.props.mediaSrc}
								></video>
								<img
									className={[classes["image"], classes["mobile"]].join(" ")}
									data-text-position={this.props.textPosition}
									alt={this.props.title}
									src={raffleImage}
								></img>
							</>
						)}
						{this.props.mediaType === "image" && (
							<img
								className={classes["image"]}
								data-text-position={this.props.textPosition}
								alt={this.props.title}
								src={this.props.mediaSrc}
							></img>
						)}
					</div>
					<div className={classes["text-container"]} data-text-position={this.props.textPosition}>
						<div className={classes["title"]}>{this.props.title}</div>
						<div className={classes["description"]}>{this.props.description}</div>
						<div className={classes["text"]}>{this.props.text1}</div>
						<div className={classes["text"]}>{this.props.text2}</div>
						<div className={classes["text"]}><strong>{this.props.text3}</strong></div>
						<div className={classes["checklist"]}>
							{this.props.checklist.map((content, index) => (
								<div key={index} className={classes["checkline"]}>
									<span className={classes["check-icon"]}>
										<CheckIcon />
									</span>
									{content}
								</div>
							))}
						</div>
						<div className={classes["text"]}>{this.props.text4}</div>
					</div>
				</div>
			</div>
		);
	}
}

