// import Blister from "assets/images/pages/mint/blister.png";
import discord from "assets/images/logos/discord3.png";
import opensea from "assets/images/logos/opensea.png";
import { DISCORD_LINK, OPENSEA_LINK } from "assets/links/links";
import MintConnectWallet from "Components/Materials/MintConnectWallet";
import PlusMinusButton from "Components/Materials/PlusMinusButton";
import Config from "Configs";
import { BigNumber } from "ethers";
import React from "react";
import EthBigNumber from "Services/Wallet/EthBigNumber";
import Contract from "Stores/Contract";
import Wallet from "Stores/Wallet";
import classes from "./classes.module.scss";
import MintInfo from "./MintInfo";
import MintPrice from "./MintPrice";

enum Phase {
	PRE_SALE = "Pre-Sale",
	PUBLIC_SALE = "Public Sale",
	ENDED = "ENDED",
}
type IProps = {};

type IState = {
	phase: Phase;
	maxSupply: number | null;
	maxMintable: number | null;
	hardcapPerUser: number | null;
	userMintedAmount: number | null;
	remainingPass: number | null;
	price: EthBigNumber | null;
	totalPrice: EthBigNumber | null;
	userAddress: string | null;
	amount: number | null;
	isWhitelisted: boolean;
	publicActive: boolean | null;
	privateActive: boolean | null;
	loading: boolean;
};

type IMintData = {
	phase: Phase;
	remainingPass: string;
	mintDate: string;
	mintPrice: string;
	amountPerUser: string;
	isWhitelisted: boolean;
};

const presaleTokenMaxTotalSupply = Config.getInstance().get().contracts.nftCollection.presaleTokenMaxTotalSupply;

export default class Mint extends React.Component<IProps, IState> {
	private removeContractOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			loading: false,
			phase: Phase.PRE_SALE,
			maxSupply: null,
			maxMintable: null,
			remainingPass: null,
			price: null,
			hardcapPerUser: null,
			userMintedAmount: null,
			totalPrice: null,
			amount: 1,
			userAddress: Wallet.getInstance().walletData?.userAddress ?? null,
			isWhitelisted: false,
			publicActive: false,
			privateActive: false,
		};
		this.onContractChange();

	}

	public render(): JSX.Element {
		const isConnected = !!this.state.userAddress;
		const privateMintDate = Config.getInstance().get().contracts.nftCollection.privateMintDate;
		const publicMintDate = Config.getInstance().get().contracts.nftCollection.publicMintDate;
		const mintDate = this.state.phase === Phase.PRE_SALE ? privateMintDate : publicMintDate;

		const mintData: IMintData = {
			phase: this.state.phase,
			remainingPass: this.state.remainingPass?.toString() ?? "... PASS",
			mintDate,
			mintPrice: this.state.price ? this.state.price.formatUnits() + " ETH" : "... ETH",
			amountPerUser:
				(this.state.userMintedAmount ?? "...") + "/" + (this.state.hardcapPerUser ?? "...") + " NFTs",
			isWhitelisted: this.state.isWhitelisted,
		};

		return (
			<div
				className={classes["root"]}
				data-minted-closed={!this.state.loading && !(this.state.privateActive || this.state.publicActive)}>
				<video autoPlay muted loop className={classes["video"]} src={"/blister.mp4"} />
				<div className={classes["sale-container"]}>
					<>
						{this.titleComponent(this.state.loading, this.state.privateActive, this.state.publicActive)}
						{this.mintInfoComponent(
							this.state.loading,
							this.state.privateActive,
							this.state.publicActive,
							mintData.phase,
							mintData.mintDate,
							mintData.mintPrice,
							mintData.amountPerUser,
							mintData.remainingPass,
						)}
						{!this.state.loading && (this.state.publicActive || this.state.privateActive) && (
							<>
								{this.eligibleComponent(mintData.phase, isConnected, mintData.isWhitelisted)}
								{this.mintAction(mintData.isWhitelisted, this.state.phase)}
							</>
						)}
					</>
				</div>
			</div>
		);
	}

	public async componentDidMount() {
		this.removeContractOnChange = Contract.getInstance().onChange(async () => this.onContractChange());
	}

	public componentWillUnmount() {
		this.removeContractOnChange();
	}

	private onContractChange() {
		this.setState({
			userAddress: Wallet.getInstance().walletData?.userAddress ?? null,
		});
		this.setState({ loading: true }, () => this.updateState());
	}

	private async updateState() {
		let price = null;
		let maxMintable = null;
		let hardcapPerUser = null;
		let phase: Phase = Phase.PRE_SALE;
		let remainingPass = null;
		let userMintedAmount = null;
		let isWhitelisted = false;
		let publicActive = false;
		let privateActive = false;

		try {
			publicActive = (await Contract.getInstance().getPublicStatus()) ?? false;
			privateActive = (await Contract.getInstance().getPrivateStatus()) ?? false;
			const maxSupply = (await Contract.getInstance().getMaxTotalSupply())?.toNumber() ?? 0;
			if (publicActive) {
				phase = Phase.PUBLIC_SALE;
				price = await Contract.getInstance().getPublicPrice();
				const privateTokensSold = (await Contract.getInstance().getPrivateTokensSold())?.toNumber();
				const publicTokensSold = (await Contract.getInstance().getPublicTokensSold())?.toNumber();
				const publicMaxMintPerTx = (await Contract.getInstance().getPublicMaxMintPerTx())?.toNumber();
				const airdropTokens = (await Contract.getInstance().getAirdropTokens())?.toNumber();
				remainingPass = maxSupply! - (privateTokensSold! + publicTokensSold! + airdropTokens!);
				if (maxSupply && publicMaxMintPerTx) {
					maxMintable = Math.min(publicMaxMintPerTx, remainingPass);
				}
				isWhitelisted = true;
			}
			if (privateActive) {
				price = await Contract.getInstance().getPrivatePrice();
				hardcapPerUser = (await Contract.getInstance().getPrivateHardcap())?.toNumber() ?? 0;
				remainingPass = Math.max(
					presaleTokenMaxTotalSupply - ((await Contract.getInstance().getTotalSupply())?.toNumber() ?? 0),
					0,
				);
				if (!!this.state.userAddress) {
					userMintedAmount =
						(
							await Contract.getInstance().getUserPrivateSaleMintedAmount(this.state.userAddress)
						)?.toNumber() ?? null;
					const privateMaxMintPerTx = (await Contract.getInstance().getPrivateMaxMintPerTx())?.toNumber();
					const userMintable = hardcapPerUser - userMintedAmount!;
					maxMintable = Math.min(privateMaxMintPerTx!, maxSupply!, userMintable);
					isWhitelisted = (await Contract.getInstance().isWhitelisted(this.state.userAddress)) ?? false;
				}
			}
		} catch (err) {
			if (!Config.getInstance().get().wallet.infuraId) {
				console.error("Missing API Key");
			}
			console.error(err);
		}

		this.setState({
			phase,
			price,
			maxMintable,
			hardcapPerUser,
			remainingPass,
			userMintedAmount,
			totalPrice: price,
			isWhitelisted,
			publicActive,
			privateActive,
			loading: false,
		});
	}

	private updateTotalPrice(value: number) {
		this.setState({
			totalPrice: this.state.price?.mul(EthBigNumber.from(value.toString(), 0)) ?? null,
			amount: value,
		});
	}

	private titleComponent(loading: boolean, privateActive: boolean | null, publicActive: boolean | null) {
		let title = "";
		let mainTitle: string = "THE PLUG EXPERIENCE";
		let poweredByTitle = "";
		let poweredByTitle2 = "";
		let poweredByTitle3 = "";
		let launchedByTitle = "";
		const nextPhase: Phase = Config.getInstance().get().nextPhase as Phase;
		if (privateActive) {
			title = Phase.PRE_SALE;
		}
		if (publicActive) {
			title = Phase.PUBLIC_SALE;
		}
		if (privateActive || publicActive) {
			launchedByTitle = "Launched by Cardel Group";
			poweredByTitle = "Powered by Ethereum";
		}
		if (!loading && !(publicActive || privateActive)) {
			switch (nextPhase) {
				case Phase.PRE_SALE:
					mainTitle = "MINT IS CLOSED";
					launchedByTitle = "Launched by Cardel Group";
					poweredByTitle = "Powered by Ethereum";
					break;
				case Phase.PUBLIC_SALE:
					title = "PRE-SALE ENDED";
					mainTitle = "SOLD OUT!";
					launchedByTitle = "600 NFT sold in less than 24 hours!";
					poweredByTitle = "After a successful launch in 2022, we’re back to redefine digital ownership and premium access.";
					poweredByTitle2 = "It’s not too late to be part of an elite community of like-minded individuals, where the only limit to your experiences is your imagination.";
					poweredByTitle3 = "Stay tuned because soon the most sought-after privileges will be at your fingertips!";
					break;
				case Phase.ENDED:
					mainTitle = "MINT IS CLOSED";
					title = "SALE ENDED";
					launchedByTitle = "Launched by Cardel Group";
					poweredByTitle = "Powered by Ethereum";
					break;
				default:
					mainTitle = "MINT IS CLOSED";
					launchedByTitle = "Launched by Cardel Group";
					poweredByTitle = "Powered by Ethereum";
					break;
			}
		}

		return (
			<div className={classes["title-container"]}>
				{loading && <div className={classes["title-loading"]} />}
				<div className={classes["phase-title"]}>{title}</div>
				<div className={classes["main-title"]}>{mainTitle}</div>
				<div className={classes["sub-title"]}>
					<div className={classes["launched-by-title"]}>{launchedByTitle}</div>
					<div className={classes["powered-by-title"]}>{poweredByTitle}</div>
					<div className={classes["powered-by-title"]}>{poweredByTitle2}</div>
					<div className={classes["powered-by-title"]}>{poweredByTitle3}</div>
				</div>
				{!loading && !(publicActive || privateActive) && (
					<div className={classes["social-container"]}>
						<div className={classes["social-row"]}>
							<div>Stay tuned for the next updates on our Discord :</div>
							<a className={classes["logo"]} href={DISCORD_LINK} target="_blank" rel="noreferrer">
								<img alt="Discord logo" src={discord} />
							</a>
						</div>
						<div className={classes["social-row"]}>
							<div>Find the collection on the OpenSea :</div>
							<a className={classes["logo"]} href={OPENSEA_LINK} target="_blank" rel="noreferrer">
								<img alt="Opensea logo" src={opensea} />
							</a>
						</div>
					</div>
				)}
			</div>
		);
	}

	private mintInfoComponent(
		loading: boolean,
		privateActive: boolean | null,
		publicActive: boolean | null,
		phase: Phase,
		mintDate: string,
		price: string,
		amountPerUser: string,
		remainingPass?: string,
	) {
		if (loading || privateActive || publicActive) {
			return (
				<div className={classes["info-container"]}>
					<MintInfo title="Mint date" value={mintDate} loading={loading} />
					{phase === Phase.PUBLIC_SALE && (
						<MintInfo title="Remaining Pass" value={remainingPass ?? ""} loading={loading} />
					)}
					<MintInfo title={phase + " Price"} value={price} loading={loading} />
					{phase === Phase.PRE_SALE && (
						<>
							<MintInfo
								title="Remaining Pass"
								value={`${remainingPass} / ${presaleTokenMaxTotalSupply} PASS`}
								loading={loading}
							/>
							<MintInfo title={phase + " amount per user"} value={amountPerUser} loading={loading} />
						</>
					)}
				</div>
			);
		}
		return null;
	}

	private eligibleComponent(phase: Phase, isConnected: boolean, isWhitelisted: boolean) {
		if (!isConnected) return null;
		if (phase === Phase.PUBLIC_SALE) return null;
		if (isWhitelisted) {
			return (
				<div className={classes["eligible-text-container"]}>
					<div className={classes["glowing-title"]}>CONGRATS</div>
					<div className={classes["legend"]}>You are eligible for the pre-sale</div>
				</div>
			);
		} else
			return (
				<div className={classes["eligible-text-container"]}>
					<div className={classes["glowing-title"]}>Unfortunately…</div>
					<div className={classes["legend"]}>Your wallet is not eligible for the Pre-Sale</div>
				</div>
			);
	}


	private mintAction(isWhitelisted: boolean, phase: Phase) {
		return (
			<>
				<div className={classes["mint-container"]}>
					<PlusMinusButton
						max={this.state.maxMintable ?? 0}
						onChange={(value) => {
							this.updateTotalPrice(value);
						}}
					/>
					<div className={classes["border"]} />
					<MintPrice price={this.state.totalPrice?.formatUnits() ?? "..."} crypto="ETH" />
				</div>
				<div className={classes["mint-button"]}>
					<MintConnectWallet
						onClick={async () => {
							let tx;
							if (phase === Phase.PRE_SALE) {
								tx = await Contract.getInstance().mintPrivateSale(
									new EthBigNumber(BigNumber.from(this.state.amount)),
									this.state.price!,
								);
							} else {
								tx = await Contract.getInstance().mintPublicSale(
									new EthBigNumber(BigNumber.from(this.state.amount)),
									this.state.price!,
								);
							}
							if (!tx) return;
							await tx.wait();
							this.updateState();
							return;
						}}
						disabled={!isWhitelisted || this.state.maxMintable === 0}
					/>
				</div>
			</>
		);
	}
}
