import React from "react";

import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";

import classes from "./classes.module.scss";
import WindowStore from "Stores/WindowStore";

type IProps = {
	title: string | JSX.Element;
	content: string | JSX.Element;
	onOpen?: (isOpen: boolean) => void;
	closeAction?: (closabled: () => void) => void;
};

type IState = {
	open: boolean;
	dropdownHeight: number;
	animate: boolean;
};

export default class Dropdown extends React.Component<IProps, IState> {
	private contentRef = React.createRef<HTMLDivElement>();
	private removeOnresize = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			open: false,
			dropdownHeight: 0,
			animate: true,
		};
	}

	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<div onClick={() => this.toogle()} className={classes["container"]}>
					<h1 className={classes["title"]}>{this.props.title}</h1>
					<span className={classes["arrow"]} data-open={this.state.open}>
						<ArrowDown />
					</span>
				</div>
				<div
					ref={this.contentRef}
					style={{ height: this.state.dropdownHeight + "px" }}
					data-open={this.state.open}
					data-animate={this.state.animate}
					className={classes["content"]}
				>
					<div className={classes["content-padding"]}>{this.props.content}</div>
				</div>
			</div>
		);
	}

	public componentDidMount() {
		if (!this.props.closeAction) return;
		this.props.closeAction(() => this.close());
		this.removeOnresize = WindowStore.getInstance().onResize(() => this.onResize());
	}

	public componentWillUnmount() {
		this.removeOnresize();
	}

	private toogle() {
		if (this.props.onOpen && !this.state.open) {
			this.props.onOpen(false);
		}

		let dropdownHeight = 0;
		if (!this.state.open) dropdownHeight = this.contentRef.current?.scrollHeight ?? 0;

		this.setState({
			dropdownHeight,
			open: !this.state.open,
			animate: true,
		});
	}

	private close() {
		this.setState({
			dropdownHeight: 0,
			open: false,
			animate: true,
		});
	}

	private onResize() {
		if (!this.state.open) return;
		this.setState(
			{
				dropdownHeight: 0,
				animate: false,
			},
			() => {
				let dropdownHeight = 0;
				if (this.state.open) dropdownHeight = this.contentRef.current?.scrollHeight ?? 0;
				this.setState({
					dropdownHeight,
					animate: false,
				});
			},
		);
	}
}
