import Period, { IProps as IPeriod } from "Components/Elements/Period";
import React from "react";
import classes from "./classes.module.scss";

export default class RealRoadmap extends React.Component {
	private periodsRoadmap: IPeriod[] = [
		{
			type: "previous",
			label: {
				date: "2022-2023",
				dateVariant: "caption",
			},
			points: [
				"Technical development",
				"Artistic & graphic development",
				"Meeting the community",
				"Partners & Advisors onboarding",
				"Artistic direction reveal",
				"NFT Private Sale",
				"First collaboration reveal"
			],
			borderVariant: "plain",
		},
		{
			type: "previous",
			label: {
				date: "2023-2024 :",
				dateVariant: "caption",
			},
			points: [
				"First version of our governance system",
				"3 to 5 Raffles per month (already 20 prizes distributed)",
				"TBA - New Opening sessions at the private club (new sales)",
				"TBA - Development of new partnerships (Web2 / Web3 / Personalities)",
				"TBA - The Plug Private Club - Second version of our Community Governance system",
				"TBA - The Plug Raffles fully deployed",
				"TBA - The Plug Marketplace development",
			],
			borderVariant: "plain",
		},
		{
			type: "next",
			label: {
				date: "2024-2025",
				dateVariant: "caption",
			},
			points: [
				"TBA - The Plug Marketplace Launch",
				"TBA - AirDrop to NFT holders",
				"TBA - The Plug Token Launch",
				"TBA - Resell of Raffles prizes on the Marketplace (claimable as NFT)",
			],
			borderVariant: "plain",
		},
	];

	public render(): JSX.Element {
		return (
			<section className={classes["root"]}>
				<div className={classes["title"]}>
					<div>
						THE PLUG <span className={classes["neon"]}>ROADMAP</span>
					</div>
				</div>
				<div className={classes["content"]}>
					<div className={classes["periods"]}>
						{this.periodsRoadmap.map((period, index) => {
							const { type, label, points, borderVariant } = period;
							return <Period key={index} type={type} label={label} points={points} borderVariant={borderVariant}/>;
						})}
					</div>
				</div>
			</section>
		);
	}
}

